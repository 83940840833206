import React from 'react'
import Grid from '@material-ui/core/Grid'
import tradinglaptop1 from '../assets/images/screenshot_detailpage.png'
// import Fade from 'react-reveal/Fade'

class EdgeHighlight extends React.Component {
  render() {
    const edgehighlight = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="pagesection_img_cont">
                <img
                  className="pagesection_img"
                  src={tradinglaptop1}
                  alt="intro"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>Edge is Everything</h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Since the introduction of electronic trading in the 1970’s
                    less than 10% of traders have been able to achieve long-term
                    positive returns, even less if price movements during bull
                    markets are normalised. EdgeForecaster was developed to help
                    the traders of the world improve their returns and become
                    more profitable. The platform and features have been built
                    to provide market edge, even at times when it’s difficult to
                    find. This level of support provides traders with greater
                    confidence and stronger returns.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <ul className="actions special" style={{ color: '#252632' }}>
                    <li>
                      <a href="/platform" className="button_main">
                        Learn More
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    const edgehighlightMob = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="pagesection_img_cont">
                <img
                  className="pagesection_img"
                  src={tradinglaptop1}
                  alt="intro"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>Edge is Everything</h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Since the introduction of electronic trading in the 1970’s
                    less than 10% of traders have been able to achieve long-term
                    positive returns, even less if price movements during bull
                    markets are normalised. EdgeForecaster was developed to help
                    the traders of the world improve their returns and become
                    more profitable. The platform and features have been built
                    to provide market edge, even at times when it’s difficult to
                    find. This level of support provides traders with greater
                    confidence and stronger returns.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <ul className="actions special" style={{ color: '#252632' }}>
                    <li>
                      <a href="/platform" className="button_main">
                        Learn More
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )

    return (
      <div className="pagesection_main">
        {/* <Fade right big> */}
        <div className="container" style={{ paddingTop: '2vh' }}>
          {this.props.pagewidth ? edgehighlightMob : edgehighlight}
        </div>
        {/* </Fade> */}
      </div>
    )
  }
}

export default EdgeHighlight
