import React from 'react'

const Intro = (props) => (
  <div className="pagesection_main">
    <div className="container" style={{ padding: '5vmin' }}>
      <hr style={{ border: '0.5px solid #ffffff' }} />
      <div className="row">
        <div className="col-12">
          <header>
            <h1 style={{ color: '#ffffff' }}>EdgeForecaster</h1>
            <p className="introhighlight">
              Quantitiative Statistics &amp; Price Forecasts produced from
              state-of-the-art artificial intelligence and machine learning
              models.
            </p>
          </header>
        </div>
        <div className="col-4 col-12-medium">
          <p>
            Being a successful trader in all market conditions is not an easy
            task. Market volatility, directional biases, commissions/margins,
            slippage, and global macro-economic influences make each and every
            trading decision fraught with potential losses. Gaining an edge on
            the market is the only solution that leads to long-term growth;
            however this is often easier said than done.
          </p>
        </div>
        <div className="col-4 col-12-medium">
          <p>
            Until now, utilising Artificial Intelligence and Machine Learning to
            gain market edge has only been an option for hedge funds and
            quantitative institutions. Advances in cloud computing and
            statistical analysis, along with improvements in market data
            availability, has meant they can now be accessed by all traders.
          </p>
        </div>
        <div className="col-4 col-12-medium">
          <p>
            EdgeForecaster evolved from ex-quant fund traders and market
            analysts who worked with a team of elite data scientists to design,
            develop and deploy state-of-the-art AI and machine learning models.
            The highly accurate price forecasts generated by these models are
            produced using factors such as seasonality, market phase, trend
            strength, momentum, sentiment and many other fundamental,
            quantitative and technical aspects.
          </p>
        </div>
      </div>
      {/* <hr style={{border: '0.5px solid #252632'}}/> */}
    </div>
  </div>
)
export default Intro
