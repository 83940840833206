import React from 'react'
import Grid from '@material-ui/core/Grid'
import quantmetrics from '../assets/images/quantmetrics.jpg'
// import Fade from 'react-reveal/Fade'

class QuantHighlight extends React.Component {
  render() {
    const quanthighlight = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>Quant Fund Metrics</h2>
                </Grid>
                <Grid item xs={12} style={{ paddingRight: '3vmin' }}>
                  <p>
                    Quant funds are known for building sophisticated models
                    around momentum, value, and financial strength, creating
                    proprietary algorithms in advanced data analysis software.
                    Until now these models and algorithms have been kept secret,
                    preventing retail traders from benefiting and achieving the
                    same level of returns. EdgeForecaster delivers these
                    sophisticated, quantitative algorithms in real-time,
                    allowing any trader to profit from the improved market edge.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <ul className="actions special" style={{ color: '#252632' }}>
                    <li>
                      <a href="/quantfundalgos" className="button_main">
                        Learn More
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <div className="pagesection_img_cont">
                <img
                  className="pagesection_img"
                  src={quantmetrics}
                  alt="intro"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    const quanthighlightMob = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="pagesection_img_cont">
                <img
                  className="pagesection_img"
                  src={quantmetrics}
                  alt="intro"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>Quant Fund Metrics</h2>
                </Grid>
                <Grid item xs={12} style={{ paddingRight: '3vmin' }}>
                  <p>
                    Quant funds are known for building sophisticated models
                    around momentum, value, and financial strength, creating
                    proprietary algorithms in advanced data analysis software.
                    Until now these models and algorithms have been kept secret,
                    preventing retail traders from benefiting and achieving the
                    same level of returns. EdgeForecaster delivers these
                    sophisticated, quantitative algorithms in real-time,
                    allowing any trader to profit from the improved market edge.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <ul className="actions special" style={{ color: '#252632' }}>
                    <li>
                      <a href="/quantfundalgos" className="button_main">
                        Learn More
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )

    return (
      <div className="pagesection_main">
        {/* <Fade right big> */}
        <div className="container" style={{ paddingTop: '2vh' }}>
          {this.props.pagewidth ? quanthighlightMob : quanthighlight}
        </div>
        {/* </Fade> */}
      </div>
    )
  }
}

export default QuantHighlight
