import React from 'react'
import Grid from '@material-ui/core/Grid'
import tradinglaptop1 from '../assets/images/trader_cropped.jpg'
// import Fade from 'react-reveal/Fade'

class MultiMarketHighlight extends React.Component {
  render() {
    const multimarkethighlight = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="pagesection_img_cont">
                <img
                  className="pagesection_img"
                  src={tradinglaptop1}
                  alt="intro"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>Multiple Markets</h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Human limitations mean there is a limit to the number of
                    markets or securities a trader can monitor and trade
                    profitably. EdgeForecaster provides access to the largest
                    markets in the world across equities, forex,
                    cryptocurrencies and commodities. Utilising the
                    lightning-fast compute infrastructure of AWS to host the
                    EdgeForecaster Cloud, a trader can unlock edge opportunities
                    and the next level of trading in a matter of seconds.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <ul className="actions special" style={{ color: '#252632' }}>
                    <li>
                      <a href="/platform" className="button_main">
                        Learn More
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    const multimarkethighlightMob = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="pagesection_img_cont">
                <img
                  className="pagesection_img"
                  src={tradinglaptop1}
                  alt="intro"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>Multiple Markets</h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Human limitations mean there is a limit to the number of
                    markets or securities a trader can monitor and trade
                    profitably. EdgeForecaster provides access to the largest
                    markets in the world across equities, forex,
                    cryptocurrencies and commodities. Utilising the
                    lightning-fast compute infrastructure of AWS to host the
                    EdgeForecaster Cloud, a trader can unlock edge opportunities
                    and the next level of trading in a matter of seconds.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <ul className="actions special" style={{ color: '#252632' }}>
                    <li>
                      <a href="/platform" className="button_main">
                        Learn More
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )

    return (
      <div className="pagesection_main">
        {/* <Fade right big> */}
        <div className="container" style={{ paddingTop: '2vh' }}>
          {this.props.pagewidth
            ? multimarkethighlightMob
            : multimarkethighlight}
        </div>
        {/* </Fade> */}
      </div>
    )
  }
}

export default MultiMarketHighlight
