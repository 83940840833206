import React from 'react'
import Grid from '@material-ui/core/Grid'
import mobilescreenshot from '../assets/images/mobilescreenshot_dual.png'
import mobileappstore from '../assets/images/mobile_appstore.png'
import mobilegoogleplay from '../assets/images/mobile_googleplay.png'
// import Fade from 'react-reveal/Fade'

class MobileHighlight extends React.Component {
  render() {
    const mobilehighlight = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>
                    Monitor the securities you trade and receive signal
                    notifications wherever you are through the EdgeForecaster
                    mobile app.
                  </h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Download the mobile application, track your strategy,
                    monitor quantitative statistics, launch trading bots, and
                    configure notifications. Whether you’re at home or on the
                    road, manage your positions anywhere.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobileappstore}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobilegoogleplay}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={4} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <div className="mobileappscreenshot_cont">
                <img
                  className="mobileappscreenshot"
                  src={mobilescreenshot}
                  alt="intro"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    const mobilehighlightMob = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <div className="mobileappscreenshot_cont">
                <img
                  className="mobileappscreenshot"
                  src={mobilescreenshot}
                  alt="intro"
                />
              </div>
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>
                    Monitor the securities you trade and receive signal
                    notifications wherever you are through the EdgeForecaster
                    mobile app.
                  </h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Download the mobile application, track your strategy,
                    monitor quantitative statistics, launch trading bots, and
                    configure notifications. Whether you’re at home or on the
                    road, manage your positions anywhere.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobileappstore}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobilegoogleplay}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )

    return (
      <div className="pagesection_main">
        <div className="container" style={{ paddingTop: '2vh' }}>
          {this.props.pagewidth ? mobilehighlightMob : mobilehighlight}
        </div>
      </div>
    )
  }
}

export default MobileHighlight
