import React from 'react'
import Grid from '@material-ui/core/Grid'
import ArticlePreview from './articlepreview'

class NewsHighlight extends React.Component {
  render() {
    var posts = this.props.posts
    const bloghighlight = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>EdgeForecaster News</h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Frequently updated news articles about the market and
                    investing, including articles about updates on
                    EdgeForcaster, the advantage our subscribers receive and
                    other topics we find interesting.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {posts.map(function ({ node }) {
                      return (
                        <Grid key={node.slug} item xs={3}>
                          <ArticlePreview article={node} />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    const bloghighlightMob = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <h2>EdgeForecaster News</h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Frequently updated news articles about the market and
                    investing, including articles about updates on
                    EdgeForcaster, the advantage our subscribers receive and
                    other topics we find interesting.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {posts.map(function ({ node }) {
                      return (
                        <Grid key={node.slug} item xs={12}>
                          <ArticlePreview article={node} />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )

    return (
      <div className="pagesection_main" style={{ paddingTop: '5vmin' }}>
        {/* <Fade duration={2000}> */}
        <div className="container">
          {this.props.pagewidth ? bloghighlightMob : bloghighlight}
        </div>
        {/* </Fade> */}
      </div>
    )
  }
}

export default NewsHighlight
