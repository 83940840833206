import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Spinner from 'react-spinner-material'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Banner from '../components/banner'
import Intro from '../components/intro'
import EdgeHighlight from '../components/edgehighlight'
import QuantHighlight from '../components/quanthighlight'
import MultiMarketHighlight from '../components/multimarket'
import FeaturesHighlight from '../components/featureshighlight'
import MobileHighlight from '../components/mobilehighlight'
import NewsHighlight from '../components/newshighlight'
import VideoSection from '../components/video_section'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class IndexPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
      loading: true,
    }
    this.changeCookie = this.changeCookie.bind(this)
  }
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    // console.log(window.innerWidth)
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    console.log(this.props.allCookies)
    this.changeCookie('lastpage', '/')
    if (!this.props.allCookies.firstload) {            
      setTimeout(() => {
        navigate('/loading')
      }, 500)
    }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Home'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var videoWidth = 1000
    var videoHeight = 600

    if (isClient) {
      videoWidth = window.innerWidth / 2.2
      videoHeight = window.innerHeight / 1.8475
    }
    if (pagewidth.matches) {
      videoWidth = 800
      videoHeight = 200
    }
    var blogposts = get(this, 'props.data.allContentfulNewsArticle.edges')
    var posts = []
    for (var i = 0; i < blogposts.length; i++) {
      var slugexists = false
      for (var j = 0; j < posts.length; j++) {
        if (blogposts[i].node.slug === posts[j].node.slug) {
          slugexists = true
        }
      }
      if ((slugexists === false) & (posts.length < 4)) {
        posts.push(blogposts[i])
      }
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#4648ff'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className="page_wrapper generalpage_wrapper">
            <Banner pagewidth={pagewidth.matches} />
            <Intro pagewidth={pagewidth.matches} />
            <EdgeHighlight pagewidth={pagewidth.matches} />
            <QuantHighlight pagewidth={pagewidth.matches} />
            <MultiMarketHighlight pagewidth={pagewidth.matches} />
            <VideoSection
              videoSrcURL="https://www.youtube.com/embed/W7-uUYi5LeE"
              videoTitle="EdgeForecaster"
              videoWidth={videoWidth}
              videoHeight={videoHeight}
            />
            <FeaturesHighlight pagewidth={pagewidth.matches} format={'index'} />
            <MobileHighlight pagewidth={pagewidth.matches} />
            <NewsHighlight pagewidth={pagewidth.matches} posts={posts} />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(IndexPage)
export const IndexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
    allContentfulNewsArticle {
      edges {
        node {
          articleId
          author {
            name
          }
          bodyRichText {
            raw
            references {
              id
            }
          }
          body {
            body
          }
          description {
            description
          }
          heroImage {
            file {
              url
            }
          }
          keywords
          publishDate(formatString: "MMMM Do YYYY")
          slug
          title
        }
      }
    }
  }
`
