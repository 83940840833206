import React from 'react'
import Grid from '@material-ui/core/Grid'

class FeaturesHighlight extends React.Component {
  render() {
    const featuresbutton = (
      <Grid container spacing={0}>
        <Grid item xs={3} />
        <Grid item xs={6} style={{ justifyContent: 'center' }}>
          <a href="/plans" className="button_main">
            SignUp Today
          </a>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    )
    const featuresbuttonMob = (
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ justifyContent: 'center' }}>
          <a href="/plans" className="button_main">
            SignUp Today
          </a>
        </Grid>
      </Grid>
    )

    return (
      <div className="pagesection_main">
        <section id="features" className="wrapper style1 special fade-up">
          <div className="container">
            {this.props.format === 'index' ? (
              <header className="major">
                <h2>EdgeForecaster Market Forecasts</h2>
                <p>
                  EdgeForecaster generates price forecasts and trading signals
                  through quantitative statistical and artificial
                  intelligence-based models, providing tools and indicators for
                  traders who want persistent market edge. EdgeForecaster
                  provides traders with the insight, confidence, and ability to
                  lock in profitable results.
                </p>
              </header>
            ) : (
              <hr />
            )}
            <div className="box alt">
              <div className="row gtr-uniform">
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-chart-area"></span>
                  <h3>Realtime Price Forecast</h3>
                  <p>
                    A real-time price forecast based on quantitative statistics,
                    seasonality, probability, and historical pattern recognition
                    brings definite edge to trading decisions. Knowing a price
                    prediction for the security you’re trading assists in
                    stop-loss management, risk determination and overall
                    confidence in your decision making, maximising returns.
                  </p>
                </section>
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-chart-bar"></span>
                  <h3>Quantitative Analytics</h3>
                  <p>
                    Quantitative analytics is more than just “mirroring the
                    past” and utilising a few indicators. Incorporating a
                    variety of statistical techniques that analyse current and
                    historical patterns, the EdgeForecaster quantitative
                    analytics have been designed to augment price forecasts,
                    provide insight into current market phases and illustrate
                    how best to allocate trading resources to achieve optimal
                    results.
                  </p>
                </section>
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-database"></span>
                  <h3>Cutting Edge Data Processing</h3>
                  <p>
                    Good data produces good results. EdgeForecaster have
                    invested significantly in developing fast and accurate data
                    pipelines, utilising global best-practices, to ensure users
                    receive to the second data and the most up-to-date
                    quantitative statistics and price forecasts possible.
                  </p>
                </section>
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-paper-plane"></span>
                  <h3>Notifcations</h3>
                  <p>
                    Never again miss a market event, change in direction, or
                    start of a price move. EdgeForecaster enables you to
                    configure notifications for triggers in your trading
                    strategy and receive the alert as the event happens or as
                    the data changes. Profitable trading comes when you’re able
                    to get in and get out at the right time, EdgeForecaster
                    notifications helps you do this each and every time.
                  </p>
                </section>
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-user-graduate"></span>
                  <h3>Edge University</h3>
                  <p>
                    The better the knowledge, the better the results.
                    Understanding the mathematical reason or the statistics
                    behind the data is key to increasing your market edge.
                    EdgeForecaster started Edge University to better edge-ucate
                    market participants as to the reason why a move is happening
                    and how a trading strategy is effective. Through the Edge-U
                    knowledge base, we aim to improve market comprehension and
                    awareness of traders and investors around the world.
                  </p>
                </section>
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-comment"></span>
                  <h3>Limited Community</h3>
                  <p>
                    Market edge is a curious thing, it only remains while the
                    rest of the market don’t know about it. Markets by nature
                    react when there is too little or too much demand but
                    knowing when this reaction is going occur is the entire
                    basis of market edge. For this reason, EdgeForecaster will
                    always be a limited community, where only a limited number
                    of subscribers can benefit from the price forecasts and
                    proprietary quantitative algorithms.
                  </p>
                </section>
              </div>
            </div>
            {this.props.format === 'index' ? (
              <footer className="major">
                {this.props.pagewidth ? featuresbuttonMob : featuresbutton}
              </footer>
            ) : (
              <div />
            )}
          </div>
        </section>
      </div>
    )
  }
}

export default FeaturesHighlight
