import React from 'react'
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid';

class ArticlePreview extends React.Component {

    render() {
        return(
            <Link to={`/news/${this.props.article.slug}`}>
                <Grid container spacing={0} className="article_preview_container">
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <img className="article_preview_img" alt="" src={this.props.article.heroImage.file.url} />
                            </Grid>
                            <Grid item xs={12}> 
                                <h3 className='article_preview_h3'>{this.props.article.title}</h3>
                            </Grid>
                            <Grid item xs={12}> 
                                <small>{this.props.article.publishDate}</small>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={12}> 
                                <p className='article_preview_p'
                                dangerouslySetInnerHTML={{
                                    __html: this.props.article.description.description,
                                }}
                                /> 
                            </Grid>            
                        </Grid>
                    </Grid>
                </Grid>
            </Link>
        )
    }
}

export default ArticlePreview
