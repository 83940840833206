import React from 'react'
import Grid from '@material-ui/core/Grid';
import pic01 from '../assets/images/laptop_screenshot_new.png'

class Banner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      mobileActiveClass: '',
      mouseOverButton: false,
      mouseOverMenu: false,
      mouseOverSubButton: false,
      mouseOverSubMenu: false,
    }
  }
  render() {
    const baselayout = 
      <div className="banneritems">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Grid className="banner_text" container spacing={0}>
                  <Grid item xs={12}>
                    <h1>Price forecasts and Quantitative Statistics for traders who want better market <span style={{color:"#4648ff", fontWeight:"bold", fontStyle:"italic"}}> EDGE</span></h1>
                  </Grid>
                  <Grid item xs={12}>
                    <p >Artificial Intelligence and machine learning backed price forecasts and quantitative data for market securities. Developed by traders for traders, EdgeForecaster is for traders who are looking for persistent returns through better market edge.</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>                        
                      <Grid item xs={6}>
                        <a href="/features" className="button_main">Learn More</a>
                      </Grid>
                      <Grid item xs={6}>
                        <a href="/video" className="button_main">Watch Video</a>
                      </Grid>
                    </Grid>                                    
                  </Grid>  
                </Grid>          
              </Grid>
              <Grid item xs={6}>
                <div className="banner_image"> 
                  <img className="banner_screenshot" src={pic01} alt="intro" />
                </div>          
              </Grid>
            </Grid>
          </Grid>
        </Grid>        
      </div>
    const baselayoutMob = 
    <div className="banneritems">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="banner_image"> 
                <img className="banner_screenshot" src={pic01} alt="intro" />
              </div>          
            </Grid>
            <Grid item xs={12}>
              <Grid className="banner_text" container spacing={0}>
                <Grid item xs={12}>
                  <h1>Price forecasts and Quantitative Statistics for traders who want better market <span style={{color:"#4648ff", fontWeight:"bold", fontStyle:"italic"}}> EDGE</span></h1>
                </Grid>
                <Grid item xs={12}>
                  <p > Artificial Intelligence and machine learning backed price forecasts and quantitative data for market securities. Developed by traders for traders looking for persistent returns through better market edge.</p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    
                    <Grid item xs={12}>
                      <a href="/features" className="button_main">Learn More</a>
                    </Grid>
                    
                  </Grid>
                  <Grid container spacing={0}>
                    
                    <Grid item xs={12}>
                      <a href="/video" className="button_main">Watch Video</a>
                    </Grid>
                    
                  </Grid>
                </Grid>  
              </Grid>          
            </Grid>                
          </Grid>
        </Grid>
      </Grid>        
    </div>

    return (
      <React.Fragment>
        {this.props.pagewidth ? 
          baselayoutMob
        :
          baselayout}
      </React.Fragment>
      );
    }
  }

export default Banner
